var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.message.id,class:[
    'timeline-item',
    _vm.message.fromBuyer && 'timeline-item-left',
    !_vm.message.fromBuyer && 'timeline-item-right'
  ]},[_c('div',{staticClass:"timeline-badge"},[_c('div',{class:[
        _vm.message.fromBuyer && 'bg-success',
        !_vm.message.fromBuyer && 'bg-warning'
      ]})]),_c('div',{staticClass:"timeline-label mt-2"},[_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.message.showDate ? _vm.message.dateDisplay : "")+" ")])]),_c('div',{staticClass:"timeline-content mb-5"},[(!_vm.message.isFile)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.message.content)}}):_vm._e(),(_vm.message.isFile)?_c('div',{staticClass:"text-hover-success cursor-pointer",on:{"click":function($event){return _vm.downloadFile(_vm.message.fileName, _vm.message.fileUrl)}}},[_c('i',{staticClass:"fas fa-file-download mr-1"}),_vm._v(" "+_vm._s(_vm.message.fileName)+" "),_c('br'),_c('p',{class:['m-0']},[_c('span',[_vm._v(_vm._s(_vm.message.fileSize))])])]):_vm._e(),_c('span',{staticClass:"text-muted font-weight-bold font-size-sm"},[_vm._v(" "+_vm._s(_vm.message.time)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }