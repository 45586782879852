var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-3x svg-icon-primary mr-3"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Github.svg"}})],1),_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.litigation ? _vm.litigation.title : null)+" ")]),_c('span',{class:[
                'ml-3 label label-xl font-weight-bold label-inline label-square',
                _vm.litigation.opened && 'label-dark',
                _vm.litigation.handled && 'label-success'
              ]},[_vm._v(" "+_vm._s(_vm.litigation.status)+" ")]),(_vm.litigation.handled)?_c('span',{class:[
                'ml-3 label label-xl font-weight-bold label-inline label-square',
                _vm.litigation.approved && 'label-success',
                _vm.litigation.canceled && 'label-danger'
              ]},[_vm._v(" "+_vm._s(_vm.litigation.decision)+" ")]):_vm._e()])]),_c('div',{staticClass:"card-body"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.litigation ? _vm.litigation.content : null)}})])]),_c('service-purchase-view',{attrs:{"service-purchase":_vm.servicePurchase,"can-be-handled":_vm.litigation.canBeHandled,"litigation":_vm.litigation},on:{"litigation-handled":_vm.litigationHandled}}),_c('chat-history',{attrs:{"chat-history":_vm.chatHistory}})],1),_c('div',{staticClass:"col-md-4"},[(_vm.litigation.handled)?_c('div',{staticClass:"alert alert-custom alert-notice alert-secondary fade show m-0 mb-5",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-3x svg-icon-secondary mr-3"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Info-circle.svg"}})],1)]),_c('div',{staticClass:"alert-text text-justify font-weight-bold"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Reason"))+" :")]),_vm._v(" "+_vm._s(_vm.litigation.reason)),_c('br')])]):_vm._e(),_c('timeline',{attrs:{"timelines":_vm.timelines}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }